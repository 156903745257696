
.record-mood-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5% 10px;
    border: solid 3px #000;
    border-radius: 15%;
    width: calc(100% - 15px);
    cursor: pointer;
}

.record-mood-container:hover {
    background-color: #000;
    color: #fff;
}

#record-mood {
    font-size: 2rem;
}

#close-modal {
    position: absolute;
    top: 35px;
    right: 0;
    cursor: pointer;
    height: 40px;
    width: auto;
}

.form-wrapper {
    position: absolute;
    top: 0;
    bottom: 10%;
    margin: auto 0;
    height: fit-content;
    width: 100%
}

.confirm-modal {
    background-color: var(--site-light-background-color);
    padding: 5px;
    width: 100%;
    border-radius: 10px;
}

.mood-image {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

#selected-image {
    max-height: 100%;
}

#modalContent {
    text-align: center;
    font-size: 2rem;
}

#discipline {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.start-session {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 2rem;
    border: solid 3px #000;
    border-radius: 15%;
    margin: 5% 0;
}
  
/* .carousel-container {
    position: relative;
    height: 40%;
    margin: auto;
    margin-top: 5%;
} */

/* #carousel {
    position: relative;
    height: 100%;
    overflow: hidden;
}

#carousel div {
    position: absolute;
    transition: transform 1s, left 1s, opacity 1s, z-index 0.5s;
    opacity: 1;
}

#carousel div img {
    width: 50%;
    transition: width 1s;
}
  
#carousel div.hide {
    left: 70%;
    opacity: 0;
    transform: translateY(50%) translateX(-50%);
}

#carousel div.hide img {
    width: 200px;
}
  
#carousel div.prev {
    z-index: 5;
    left: 30%;
    width: 25%;
    transform: translateY(50px) translateX(-50%);
}

#carousel div.prev img {
    width: 100%;
}
  
#carousel div.selected {
    z-index: 10;
    left: 50%;
    width: 50%;
    transform: translateY(0px) translateX(-50%);
}

#carousel div.selected img {
    width: 100%;
}
  
#carousel div.next {
    z-index: 5;
    left: 70%;
    width: 25%;
    transform: translateY(50px) translateX(-50%);
}

#carousel div.next img {
    width: 100%;
    object-fit: cover;
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: 0.5;
    transition: opacity 0.15s ease;
}

.carousel-control-next {
    right: 0;
}

.carousel-control-prev {
    left: 0;
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%;
}

.carousel-control-prev-icon {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */

/* .visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}

.carousel-radio {
    height: 0;
} */