/* Carousel.css */
/* .slick-slide {
    transition: transform 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
  } */
  
  .slick-prev,
  .slick-next {
    font-size: 1.5rem;
    z-index: 1;
  }
  
  .slick-prev {
    left: 10px;
  }
  
  .slick-next {
    right: 10px;
  }
  
  /* Adjust the size of the side images */
  /* .slick-slide img {
    width: 70%; Adjust as needed
    height: auto;
  } */
  
  /* Enlarge the center image */
  /* .slick-current img {
    width: 100%;
    height: auto;
    transform: scale(1.2); Adjust the scale factor as needed
  } */
  