
.wrapper-main {
    width: 400px;
    height: calc(100dvh - 56px);
    margin: 0 auto;
    max-height: calc(100dvh - 56px);
    padding-top: 20px;
    position: relative; 
    overflow-y: hidden;
}
  
.col-6 {
    width: 50%;
    display: flex;
}

.img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 100%;
    max-height: 200px;
    margin: 5% 0 10% 0;
}

.img-container img {
    height: 100%;
    width: auto;
    max-height: 20vh;
}

/*user home buttons*/
.menu-btn {
    width: 100%;
    height: 100%;
    /* background-color: var(--site-color-01); */
    border-radius: 25%;
    display: flex;
    align-items: center;
    text-align: center;
    background-color: #E0E0E0;
    color: #333;
    /* border: 2px solid #83FFE7; */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.5);
}

/*user home row*/
.row {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 3%;
    margin-top: 5%;
}

/*user home row btn*/
.row a {
    width: 90px;
    height: 90px;
    
}
.entries {
    width: 400px;
    position: fixed;
    top: 93px;
    bottom: 65px;
    left: 50%;
    transform: translateX(-50%); 
    overflow: auto;
    overflow-x: hidden;
}

.entry {
    background-color: var(--site-light-background-color);
}

.entries-in-tabs {
    width: 100%;
    max-height: calc(100dvh - 195px);
    overflow: auto;
    overflow-x: hidden;
}

.entry-in-tabs {
    width: 100%;
}

.new-entry {
    width: 400px;
    background-color: var(--site-light-background-color);
    border-radius: 10px;
    padding: 10px 5px;
    position: fixed;
    top: 80px;
    max-height: calc(100dvh - 56px - 93px - 5px);
    left: 50%;
    transform: translateX(-50%);
    overflow: auto;
}

.add-subtract-button {
    background-color: #fff !important;
    font-size: 1.2rem !important;
    border: 1px solid var(--site-primary-color) !important;
}

.bottom-buttons, .hydration-buttons, .flex-buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-add-buttons {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 60px;
    left: 50%;
    transform: translateX(-50%);
}

.bottom-buttons-fullwidth {
    display: flex;
    align-items: center;
    justify-content: center;

}

/* Mobile version */
@media only screen and (max-width: 400px) {

    .wrapper-main, .entries, .new-entry {
        width: calc(100% - 40px);
    }
}


