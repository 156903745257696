html, body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 62.5%;
  height: 100dvh !important;
  min-height: 100dvh !important;
  max-height: 100dvh !important;
  width: 100%;
  /* background-image: linear-gradient(#5b8bdf, #00cce5); */
  background-color: #545454;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: calc(100dvh - 56px);
  overflow-y: hidden;
}

:root {
  --site-background-color: #545454;
  --site-light-background-color: #E0E0E0;
  --site-primary-color: #83FFE7;
  --site-light-text-color: #E0E0E0;
  --site-dark-text-color: #545454;
  --site-black-text-color: #000;
}

h1 {
  font-size: 2.6rem;
  line-height: 1.5;
  color: var(--site-light-text-color);
  font-weight: 600;
  text-align: center;
}

h2 {
  font-size: 2.8rem;
  line-height: 1.5;
  color: var(--site-dark-text-color);
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

h3 {
  font-size: 1.8rem;
  line-height: 1.5;
  color: var(--site-dark-text-color);
  font-weight: 600;
  text-transform: capitalize;
  text-align: center;
}

h4 {
  text-align: start;
  font-size: 1.6rem;
}

p {
  font-size: 1.4rem;
  line-height: 1.75;
  color: var(--site-dark-text-color);
  /* font-family: "Comic Sans", "Comic Sans MS", "Chalkboard", "ChalkboardSE-Regular", sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  width: 100%;
  text-align: center;
}

a {
  font-size: 1.4rem;
  line-height: 1.75;
  color: var(--site-light-text-color);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  cursor: pointer;
}

input {
  color: var(--site-light-text-color);
}
