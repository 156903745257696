
.skill-bottom-button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 2rem;
    border: solid 3px #000;
    border-radius: 15%;
    margin: 5% 1%;
}
